<template>
  <div class="back">
    <div class="top_white">
      <div class="top_back" @click="go_back()">
        <van-image :src="left_img" width="9" height="15" :show-loading="false" />
        <span style="margin-left:10px">确认订单</span>
      </div>
    </div>
    <div class="back_inner">
      <div class="back_more_inner">
        <div class="the_slot"></div>
        <div class="first_line">
          <div class="first_left">
            <div class="first_left_text">所购课程将放入此账号内</div>
          </div>
          <div class="first_right" v-if="isLogin" @click="checkoutAccount">
            <img class="checkout" src="@/icon/user_checkout.svg" alt />切换账号
          </div>
          <div
            class="first_right"
            style="color:#06D167"
            v-if="!isLogin && isWXBrowser"
            @click="getWXUserinfo"
          >
            <img class="wx" src="@/icon/wechat.svg" alt /> 使用微信登录
          </div>
        </div>
        <div class="second_line" v-if="isLogin">
          <div class="right_icon">
            <van-image :src="userAtavar" fit="cover" round class>
              <template v-slot:loading>
                <img
                  src="http://qiniu.stylika.online/01612694929603h=273"
                  class="van-image"
                  style="object-fit:contain"
                />
              </template>
            </van-image>
          </div>
          <div class="left_text">
            <div class="name_text">{{userName}}</div>
            <div class="other_text">
              <div style="white-space:nowrap">账号 {{userId}}</div>
              <div style="margin-left:20px" class="regTime">注册时间 {{userRegisterTime}}</div>
            </div>
          </div>
        </div>
        <div class="phone_ipt" v-else>
          <van-cell-group :border="false">
            <van-field v-model="phone" placeholder="请输入手机号码" type="tel" maxlength="11" />
            <van-field
              v-model="verifyCode"
              placeholder="请输入验证码"
              type="number"
              maxlength="6"
              @blur="login($event)"
            >
              <template #button>
                <van-button
                  size="small"
                  style="border-radius:3px;width:2.3rem;height:0.8rem;font-size:12px;"
                  :color="isSend?'grey':'linear-gradient(to left, #EA2929, #E88787)'"
                  @click="isSend?'':getVerifyCode()"
                >{{verifyText}}</van-button>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div class="third_line" v-if="isLogin" @click="addAddress">
          <div class="third_first" :style="{width:isHaveAddr?'':'0.1rem',margin:isHaveAddr?'':'0'}">
            <div class="address_info" v-if="isHaveAddr">
              <div class="user_add">{{address_info.province+address_info.city+address_info.area}}</div>
              <div class="user_add_des">{{address_info.description}}</div>
              <div class="user_add user_add_info">
                <span>{{address_info.name}}</span>
                <span style="margin-left:10px">{{address_info.phone}}</span>
              </div>
            </div>
          </div>
          <div class="third_mid" v-if="!isHaveAddr">添加配送地址</div>
          <div class="third_last">
            <van-image :src="right_img" fit="cover" class :show-loading="false" />
          </div>
        </div>
        <div class="fouth_line" :style="{marginTop:isLogin?'':'60px'}">
          <div class="fouth_inner">
            <div class="fouth_first">
              <img
                src="http://qiniu.stylika.online/31622631401968h=500"
                style="width:21px;height:20px"
              />
              {{storeList.store_name}}
              <van-image :src="right_img" fit="cover" :show-loading="false" />
            </div>
            <div class="fouth_second">
              <div class="fouth_second_left">
                <van-image :src="courseData.pic" fit="cover" class>
                  <template v-slot:loading>
                    <img
                      src="http://qiniu.stylika.online/01612694929603h=273"
                      class="van-image"
                      style="object-fit:contain"
                    />
                  </template>
                </van-image>
              </div>
              <div class="fouth_second_right">
                <div>{{courseData.product_name}}</div>
                <div class="right_bottom">
                  <div class="right_bottom_little">¥</div>
                  <div class="right_bottom_big">{{courseData.price}}</div>
                  <div>x {{courseData.quantity}}</div>
                </div>
              </div>
            </div>
            <div class="common_div" style="margin-top:20px" v-if="isVIP">
              <div class="left_div">会员优惠</div>
              <div class="common_first">
                省 {{vipDiscountPrice}} 元
                <!-- <van-image :src="right_img" fit="cover" :show-loading="false"  /> -->
              </div>
            </div>
            <div class="common_div" style="margin-top:30px">
              <!-- <div class="left_div">备注</div>
              <div class="common_left">
                <van-field
                  v-model="note_value"
                  placeholder="请填写备注信息(选填)"
                  maxlength="100"
                  clearable
                />
              </div>-->
            </div>
            <div class="common_div">
              <div class="left_div">发票</div>
              <div class="common_left common_other">暂不支持</div>
            </div>
            <div class="fouth_last">
              <div class="last_first">共{{courseData.quantity}}件</div>
              <div class="last_second">小计</div>
              <div class="last_third">
                <div>¥</div>
                <div class="big_red">{{currentPrice*courseData.quantity}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_line">
          <div class="bottom_first">共{{courseData.quantity}}件商品，合计</div>
          <div class="bottom_price">
            <div>¥</div>
            <div class="big_red">{{currentPrice*courseData.quantity}}</div>
          </div>
          <div class="pay_button" @click="pay">支付</div>
        </div>
      </div>
    </div>
    <van-dialog v-model="isGoPay" title="请确认是否已付款" :showConfirmButton="false">
      <div class="dialog_mes">如已付款，请点击'已完成付款</div>
      <div class="dialog_btn_group">
        <van-button plain round color="#EA2929" class="btn" @click="isFinsh?'':openNewOrder()">未付款</van-button>
        <van-button
          round
          class="btn"
          :loading="isFinsh"
          color="linear-gradient(to left, #EA2929, #E88787)"
          @click="finshOrder"
        >已完成付款</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      courseId: 1,
      orderId: "",
      orderPaymentData: {},
      shareImg: "http://qiniupc.stylika.online/upload_pic_1608881029316_h=500",
      right_img: "http://qiniupc.stylika.online/upload_pic_1615448838131_h=12",
      left_img: "http://qiniupc.stylika.online/upload_pic_1615452264549_h=18",
      courseData: {},
      storeList: {},
      note_value: "",
      phone: "",
      verifyCode: "",
      verifyText: "发送验证码",
      verifySec: 60,
      isSend: false,
      isLogin: false,
      isVIP: false,
      vipDiscountPrice: 0,
      vip_discount: 1,
      currentPrice: 0,
      userName: "",
      userId: 1,
      userAtavar: "",
      userRegisterTime: "",
      address_info: {},
      isHaveAddr: false,
      isGoPay: false,
      isFinsh: false,
      isWXBrowser: false
    };
  },
  async mounted() {
    this.isWXBrowser = await this.$store.dispatch("isWXBrowser");

    this.isLogin = window.localStorage.getItem("isStylikaH5Login")
      ? JSON.parse(window.localStorage.getItem("isStylikaH5Login"))
      : false;
    if (this.isWXBrowser) {
      let code = this.getUrlKey("code");
      let isGetWXLoginCode =
        window.localStorage.getItem("isGetWXLoginCode") || false;
      if (code) {
        this.WXCode = code;
        window.localStorage.setItem("WXCode", code);
        this.getWXConfigData();
      } else {
        this.getWXCode("snsapi_base");
      }

      if (!this.isLogin && isGetWXLoginCode) {
        this.wxLogin();
      }
    }
    if (this.$route.query.id) {
      this.courseId = this.$route.query.id;
    }
    if (window.localStorage.getItem("CourseData")) {
      this.courseData = JSON.parse(window.localStorage.getItem("CourseData"));
      this.storeList = this.courseData.storeList;
    } else {
      let courseInfo = await this.$iHttp.get(
        "/api/lesson/info/?id=" + this.courseId
      );
      let courseData = {
        des: JSON.parse(courseInfo.data.data.description).classShort,
        is_pre_sale: false,
        pic: courseInfo.data.data.photo_main,
        price: courseInfo.data.data.price,
        product_id: this.courseId,
        product_name: courseInfo.data.data.course_name,
        quantity: 1,
        storeList: {
          store_name: courseInfo.data.data.instructor_profile.display_name,
          store_icon: courseInfo.data.data.instructor_profile.display_icon,
          store_id: courseInfo.data.data.instructor_profile.user_id
        }
      };
      this.courseData = courseData;
      this.storeList = this.courseData.storeList;
    }

    this.currentPrice = Number(this.courseData.price);

    if (this.isLogin) {
      let userData = JSON.parse(window.localStorage.getItem("userData"));
      if (userData) {
        this.userName = userData.userName;
        this.userId = userData.userId;
        this.userAtavar = userData.userAtavar;
        this.userRegisterTime = userData.userRegisterTime;
      }
      this.vip_discount = this.$store.state.vip_discount;
      this.isVIP = JSON.parse(window.localStorage.getItem("isStylikaVIP"));
      if (this.isVIP) {
        this.currentPrice = (
          this.vip_discount * Number(this.courseData.price)
        ).toFixed(2);
        this.vipDiscountPrice = (
          Number(this.courseData.price) - Number(this.currentPrice)
        ).toFixed(2);
      }
      this.getAddress();
      this.orderPaymentData = window.localStorage.getItem("WXPaymentData")
        ? JSON.parse(window.localStorage.getItem("WXPaymentData"))
        : {};
      if (
        Object.keys(this.orderPaymentData).length != 0 &&
        this.orderPaymentData.orderId != ""
      ) {
        this.orderId = this.orderPaymentData.orderId;
        this.checkOrderStatus();
      }
    }
  },
  activated() {
    console.log("activated");
  },
  methods: {
    getWXCode(scope) {
      let urlNow = encodeURIComponent(this.funcUrlDel("code") || location.href);
      // let scope = "snsapi_base"; //应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
      let appid = "wx467928844a5c4ee0";
      let state = "stylikaH5";
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.href = url;
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    funcUrlDel(name) {
      var loca = window.location;
      var baseUrl = loca.origin + loca.pathname + "?";
      var query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        var obj = {};
        var arr = query.split("&");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        var url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, "")
            .replace(/\:/g, "=")
            .replace(/\,/g, "&");
        return url;
      }
    },
    getWXConfigData() {
      this.$iHttp
        .post("/api/lesson/purchase/get_sign/", {
          url: location.href.split("#")[0]
        })
        .then(res => {
          this.$wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wx467928844a5c4ee0", // 必填，公众号的唯一标识
            timestamp: res.data.data.sign.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.sign.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.sign.signature, // 必填，签名
            jsApiList: [
              "chooseWXPay",
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ],
            success(res) {
              console.log(res);
            },
            fail(err) {
              console.log(err);
            }
          });
          let that = this;
          this.$wx.ready(function() {
            //设置消息分享参数
            that.setShareMessageParameters();
            //设置朋友圈分享参数
            that.setShareTimelineParameters();
          });
        });
    },
    setShareMessageParameters() {
      this.$wx.updateAppMessageShareData({
        title: this.courseData.product_name, // 分享标题
        desc: this.courseData.des, // 分享描述
        link: location.origin + "?id=" + this.courseData.product_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.courseData.pic, // 分享图标
        success: function() {
          // 设置成功
          // alert("SetShareMessageParameters success!");
        },
        fail: function(err) {
          // 设置失败
          // alert("SetShareMessageParameters fail!" + err.errMsg);
        }
      });
    },
    setShareTimelineParameters() {
      this.$wx.updateTimelineShareData({
        title: this.courseData.product_name, // 分享标题
        link: location.origin + "?id=" + this.courseData.product_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.courseData.pic, // 分享图标
        success: function() {
          // 设置成功
          // alert("SetShareTimelineParameters success!");
        },
        fail: function(err) {
          // 设置失败
          // alert("SetShareMessageParameters fail!" + err.errMsg);
        }
      });
    },
    go_back() {
      this.$router.push({
        path: "/",
        query: {
          id: this.courseData.product_id || this.courseId
        }
      });
    },
    getVerifyCode() {
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (reg.test(this.phone)) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "正在发送"
        });
        this.isSend = true;
        let timer = setInterval(() => {
          this.verifyText = this.verifySec;
          this.verifySec--;
          if (this.verifySec < 0 || this.isLogin) {
            this.verifyText = "发送验证码";
            this.verifySec = 60;
            this.isSend = false;
            clearInterval(timer);
          }
        }, 1000);
        this.$iHttp
          .post("/api/bind_phone/", {
            get_code: true,
            ode_type: "login",
            contact_phone: this.phone
          })
          .then(res => {
            if (res.data.errcode === 0) {
              this.$toast("发送成功");
            } else {
              this.$toast("发送失败");
            }
            this.$toast.clear();
          })
          .catch(err => {
            this.$toast.clear();
            this.$toast("发送失败");
          });
      } else {
        this.$toast("请正确填写手机号!");
      }
    },
    getWXUserinfo() {
      this.getWXCode("snsapi_userinfo");
      window.localStorage.setItem("isGetWXLoginCode", true);
    },
    wxLogin() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "正在登录"
      });
      this.$iHttp
        .post("/api/web/log_in/", {
          code: this.WXCode
        })
        .then(res => {
          if (res.data.errcode == -1) {
            this.$toast("网络错误");
          } else if(res.data.access) {
            this.$toast.clear();
            this.getAddress();
            this.isLogin = true;
            this.isVIP = res.data.member_expired_date
              ? new Date(res.data.member_expired_date).getTime() >=
                new Date().getTime()
              : false;
            this.userName = res.data.display_name;
            this.userAtavar = res.data.display_icon;
            this.userId = res.data.user_id;
            this.userRegisterTime = this.$tools.timeFormat(
              new Date(res.data.register_time).getTime(),
              "yyyy-mm-dd hh:MM"
            );
            let userData = {
              userName: this.userName,
              userAtavar: this.userAtavar,
              userId: this.userId,
              userRegisterTime: this.userRegisterTime
            };
            window.localStorage.setItem("access", res.data.access);
            window.localStorage.setItem("refresh", res.data.refresh);
            window.localStorage.setItem("isStylikaVIP", this.isVIP);
            window.localStorage.setItem("isStylikaH5Login", this.isLogin);
            window.localStorage.setItem("userData", JSON.stringify(userData));
            //获得code
                this.getWXCode("snsapi_base");
          }
        })
    },
    login($val, isReSignup) {
      if (this.verifyCode.length > 5) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "正在登录"
        });
        let signData = {
          code_type: "login",
          display_icon: "http://qiniu.stylika.online/11621491570510h=288",
          get_code: false,
          web: true,
          code: this.verifyCode,
          contact_phone: this.phone
        };
        if (isReSignup) {
          signData.is_sign_up = true;
        } else {
          signData.is_sign_up = false;
        }
        this.$iHttp
          .post("/api/bind_phone/", signData)
          .then(res => {
            if (res.data.errcode === 2012) {
              this.login(true, true);
            } else if (res.data.access) {
              this.getAddress();
              this.isLogin = true;
              this.isVIP = res.data.member_expired_date
                ? new Date(res.data.member_expired_date).getTime() >=
                  new Date().getTime()
                : false;
              this.userName = res.data.display_name;
              this.userAtavar = res.data.display_icon;
              this.userId = res.data.user_id;
              this.userRegisterTime = this.$tools.timeFormat(
                new Date(res.data.register_time).getTime(),
                "yyyy-mm-dd hh:MM"
              );
              let userData = {
                userName: this.userName,
                userAtavar: this.userAtavar,
                userId: this.userId,
                userRegisterTime: this.userRegisterTime
              };
              window.localStorage.setItem("access", res.data.access);
              window.localStorage.setItem("refresh", res.data.refresh);
              window.localStorage.setItem("isStylikaVIP", this.isVIP);
              window.localStorage.setItem("isStylikaH5Login", this.isLogin);
              window.localStorage.setItem("userData", JSON.stringify(userData));
            } else {
              this.$toast(
                res.data.errcode == 4016 ? "验证码已失效" : res.data.detail
              );
              switch (res.data.errcode) {
                case 4016:
                  this.$toast("验证码已失效");
                  break;
                case 4017:
                  this.$toast("验证码不正确");
                  break;
                case 4025:
                  this.$toast("未发送验证码");
                  break;
                default:
                  this.$toast("登录失败");
                  break;
              }
            }
          })
          .catch(err => {
            this.$toast("登录失败");
          })
          .finally(fin => {
            // this.$toast.clear();
          });
      } else {
        this.$toast("请正确填写验证码!");
      }
    },
    checkoutAccount() {
      window.localStorage.clear();
      this.isLogin = false;
      this.isVIP = false;
      this.isHaveAddr = false;
      this.currentPrice = this.courseData.price;
      this.phone = "";
      this.verifyCode = "";
      this.isSend = false;
      this.verifyText = "发送验证码";
      this.verifySec = 60;
    },
    addAddress() {
      this.$router.push("/addressList");
    },
    getAddress() {
      if (Object.keys(this.$store.state.userAddressData).length == 0) {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中..."
        });
        this.$iHttp
          .get("/api/shopping_car/user_address/")
          .then(res => {
            this.isHaveAddr = res.data.data.address_info.length > 0;
            this.address_info = this.isHaveAddr
              ? res.data.data.address_info[0]
              : {};
          })
          .catch(err => {
            console.log(err);
          })
          .finally(fin => {
            this.$toast.clear();
          });
      } else {
        this.address_info = this.$store.state.userAddressData;
        this.isHaveAddr = true;
      }
    },
    pay() {
      if (this.isLogin) {
        if (Object.keys(this.address_info).length !== 0) {
          this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "正在支付"
          });

          if (this.isWXBrowser) {
            let code = this.WXCode || window.localStorage.getItem("WXCode");
            let payData = {
              price: this.currentPrice,
              address_id: this.address_info.id,
              user_notes: this.note_value,
              course_id: this.courseData.product_id,
              internal: true,
              code: code
            };
            if (
              Object.keys(this.orderPaymentData).length != 0 &&
              this.orderPaymentData.orderId != ""
            ) {
              // 二次支付参数
              payData.order_id = this.orderPaymentData.orderId;
            }
            this.wxBrowserPayMethod(payData);
          } else {
            if (
              Object.keys(this.orderPaymentData).length != 0 &&
              this.orderPaymentData.orderId != ""
            ) {
              window.location.href = this.orderPaymentData.url;
              this.$toast.clear();
            } else {
              this.$iHttp
                .post("/api/lesson/purchase/", {
                  price: this.currentPrice,
                  address_id: this.address_info.id,
                  user_notes: this.note_value,
                  course_id: this.courseData.product_id
                })
                .then(res => {
                  if (res.data.errcode == -1) {
                    this.$toast("网络错误");
                  } else if (res.data.errcode == -2) {
                    this.$toast("课程不见了~");
                  } else {
                    this.$toast.clear();
                    window.location.href = res.data.data.res_data.mweb_url;
                    this.orderId = res.data.data.order_list[0];
                    let WXPaymentData = {
                      orderId: res.data.data.order_list[0],
                      url: res.data.data.res_data.mweb_url
                    };
                    window.localStorage.setItem(
                      "WXPaymentData",
                      JSON.stringify(WXPaymentData)
                    );
                    this.orderPaymentData = WXPaymentData;
                    this.checkOrderStatus();
                  }
                })
                .catch(err => {
                  this.$toast("网络错误");
                })
                .finally(fin => {});
            }
          }
        } else {
          this.$toast("请添加配送地址");
        }
      } else {
        this.$toast("未登录");
        window.scrollTo(0, 0);
      }
    },
    wxBrowserPayMethod(payData) {
      this.$iHttp
        .post("/api/lesson/purchase/", payData)
        .then(res => {
          if (res.data.errcode == -1) {
            this.$toast("网络错误");
          } else if (res.data.errcode == -2) {
            this.$toast("课程不见了~");
          } else if (res.data.errcode == 4026) {
            this.$toast("订单已过期！");
          } else {
            this.$toast.clear();
            this.orderId = res.data.data.order_list[0];

            let WXPaymentData = {
              orderId: res.data.data.order_list[0]
            };
            window.localStorage.setItem(
              "WXPaymentData",
              JSON.stringify(WXPaymentData)
            );

            this.orderPaymentData = WXPaymentData;
            let that = this;
            // 执行支付
            this.$wx.chooseWXPay({
              timestamp: res.data.data.res_data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.data.data.res_data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.data.res_data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res.data.data.res_data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: res.data.data.res_data.paySign, // 支付签名
              success: res => {
                that.checkOrderStatus();
              },
              error: err => {
                //获得code
                that.getWXCode("snsapi_base");
              },
              cancel: function(cel) {
                //获得code
                that.getWXCode("snsapi_base");
              },
              complete: function(col) {}
            });

            // WeixinJSBridge.invoke(
            //   "getBrandWCPayRequest",
            //   {
            //     appId: "wx467928844a5c4ee0", //公众号ID，由商户传入
            //     timeStamp: res.data.data.res_data.timeStamp, //时间戳，自1970年以来的秒数
            //     nonceStr: res.data.data.res_data.nonceStr, //随机串
            //     package: res.data.data.res_data.package,
            //     signType: res.data.data.res_data.signType, //微信签名方式：
            //     paySign: res.data.data.res_data.paySign //微信签名
            //   },
            //   function(res) {
            //     console.log(res)
            //     this.$toast(res.err_msg)
            //     if (res.err_msg == "get_brand_wcpay_request:ok") {
            //       this.checkOrderStatus();
            //       this.$toast("支付成功");
            //     }
            //   },
            // );
          }
        })
        .catch(err => {
          this.$toast("网络错误");
        })
        .finally(fin => {});
    },
    checkOrderStatus() {
      this.$iHttp
        .post("/api/lesson/check_result/", {
          order_id: this.orderId
        })
        .then(res => {
          if (res.data.errcode == 2014) {
            this.isGoPay = true;
          } else if (res.data.errcode == 2015) {
            this.$toast.success({
              duration: 0,
              forbidClick: true,
              message: "购买成功"
            });
            setTimeout(() => {
              this.go_back();
            }, 1000);
          } else {
            this.$toast("网络错误");
          }
        });
    },
    finshOrder() {
      this.isFinsh = true;
      this.$iHttp
        .post("/api/lesson/check_result/", {
          order_id: this.orderPaymentData.orderId
        })
        .then(res => {
          if (res.data.errcode == 2014) {
            this.$toast("未完成付款！");
          } else if (res.data.errcode == 2015) {
            this.$toast.success({
              duration: 0,
              forbidClick: true,
              message: "购买成功"
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.$toast("网络错误");
          }
        })
        .finally(fin => (this.isFinsh = false));
    },
    openNewOrder() {
      this.isGoPay = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  display: flex;
  justify-content: center;

  .dialog_mes {
    color: #b2b2b2;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .dialog_btn_group {
    width: 80%;
    margin-left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;

    .btn {
      width: 3rem;
      height: 0.94rem;
      font-size: 14px;
    }
  }

  .top_white {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    height: 1.4rem;
    border-bottom: 1px solid #f5f5f5;
    .van-image {
      width: 0.18rem;
      height: 0.34rem;
      margin-left: 0.5rem;
    }
    font-size: 17px;
    font-weight: 500;

    .top_back {
      display: flex;
      align-items: center;
    }
  }
  .back_inner {
    width: 100%;
    max-width: 12rem;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    .back_more_inner {
      width: 93%;

      .phone_ipt {
        width: 100%;
        //   border:1px solid red;
      }
      .the_slot {
        height: 1.9rem;
      }
      .first_line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        .first_left {
          border-radius: 9px 9px 0 0;
          border-bottom: 30px solid #ffffff;
          border-right: 20px solid transparent;
          height: 0;
          .first_left_text {
            margin: 8px;
          }
        }
        .first_right {
          color: #de0000;
          margin-right: 0.15rem;
          font-size: 14px;
          display: flex;
          align-items: center;
          img {
            margin-right: 3px;
          }
          .wx {
            width: 0.64rem;
            height: 0.4rem;
          }
          .checkout {
            width: 0.64rem;
            height: 0.4rem;
          }
        }
      }
      .second_line {
        background-color: white;
        display: flex;
        border-radius: 0 9px 9px;
        padding: 25px 0 25px 10px;
        .right_icon {
          height: 1.45rem;
          display: flex;
          align-items: center;
          margin-right: 0.4rem;
          .van-image {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        .left_text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name_text {
            font-size: 23px;
          }
          .other_text {
            font-size: 12px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            width: 7.8rem;
            margin-top: 5px;

            .regTime {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .third_line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        padding: 12px 0;
        border-radius: 10px;
        margin: 20px 0;
        .third_first {
          width: 70%;
          margin-left: 20px;

          .address_info {
            .user_add {
              font-size: 14px;
              color: #b2b2b2;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .user_add_des {
              margin: 5px 0;
              font-size: 16px;
              color: #000;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .third_mid {
          font-size: 16px;
          color: #ff9802;
        }
        .third_last {
          width: 0.5rem;
          .van-image {
            width: 0.18rem;
            height: 0.34rem;
          }
        }
      }
      .fouth_line {
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        .fouth_inner {
          .fouth_first {
            display: flex;
            align-items: center;
            font-size: 12px;
            .van-image {
              width: 5px;
              height: 10px;
              margin-left: 0.2rem;
            }
            img {
              width: 0.4rem;
              height: 0.4rem;
              margin-right: 0.15rem;
            }
          }
          .fouth_second {
            display: flex;
            margin: 0.5rem 0;
            .fouth_second_left {
              margin-right: 0.4rem;
              .van-image {
                width: 2.4rem;
                height: 2.4rem;
              }
            }
            .fouth_second_right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .right_bottom {
                display: flex;
                color: #808080;
                font-size: 14px;
                align-items: flex-end;
                .right_bottom_little {
                  color: #de0000;
                }
                .right_bottom_big {
                  color: #de0000;
                  font-size: 20px;
                  margin-right: 0.1rem;
                }
              }
            }
          }
          .common_div {
            display: flex;
            font-size: 14px;
            margin: 10px 0;
            .left_div {
              //   width: 2rem;
              display: flex;
              //   justify-content: flex-end;
              margin-right: 0.7rem;
              align-items: center;
            }
            .common_first {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 6.5rem;
              color: #de0000;
              .van-image {
                width: 0.18rem;
                height: 0.34rem;
                margin-left: 0.2rem;
              }
            }
            .common_left {
              color: #c0c0c0;
            }
            .common_other {
              margin-left: 15px;
            }
          }
          .fouth_last {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin: 20px 10px 20px 0;
            .last_first {
              color: #c0c0c0;
              margin-right: 0.3rem;
            }
            .last_second {
            }
            .last_third {
              display: flex;
              color: #de0000;
              align-items: flex-end;
              margin: 0 0.3rem;
              .big_red {
                font-size: 22px;
                margin-left: 0.1rem;
              }
            }
          }
        }
      }
      .bottom_line {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        .bottom_first {
          font-size: 14px;
        }
        .bottom_price {
          display: flex;
          color: #de0000;
          align-items: flex-end;
          margin: 0.3rem 0;
          .big_red {
            font-size: 25px;
          }
        }
        .pay_button {
          width: 5.5rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: -webkit-linear-gradient(left, #e88787, #ea2929);
          border-radius: 67px;
          color: white;
          font-size: 18px;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>